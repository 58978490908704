import './layouts/css/bootstrap.min.css';
import './layouts/css/global.css';


const mobileScreenWidth = 975;
function toggleNavBG(condition) {
  if (condition) document.querySelector('.navbar').classList.add('navbar-bg');
  else document.querySelector('.navbar').classList.remove('navbar-bg');
}
function pageWidthChanges() {
  var width = Math.max(
    document.body.scrollWidth,
    document.documentElement.scrollWidth,
    document.body.offsetWidth,
    document.documentElement.offsetWidth,
    document.documentElement.clientWidth
  );
  toggleNavBG(width < mobileScreenWidth);
  return width;
}

window.addEventListener("resize", pageWidthChanges);
toggleNavBG(window.screen.width < mobileScreenWidth);
pageWidthChanges();

var height = document.getElementsByTagName('section')[0].offsetHeight;

window.addEventListener('scroll', (e) => {
  if (pageWidthChanges() > mobileScreenWidth) {
    toggleNavBG(window.scrollY >= (height / 6));
  }
});

// toggle mobile nav icon
document.querySelector('.navbar-toggler').addEventListener('click', (e) => {
  var el = document.querySelector('.navbar-toggler svg');
  el.classList.contains('fa-bars') ? el.classList.add('fa-times') : el.classList.add('fa-bars');
});

// active class
document.querySelectorAll('.navbar a').forEach(link => {
  if (link.dataset.href) {
    var el = document.getElementById(`${link.dataset.href}`);
    link.addEventListener('click', () => {
      if (link.dataset.href == 'technologies') {
          var pos = el.style.position,
            top = el.style.top;
          el.style.position = 'relative';
          el.style.top = '-90px';
          el.scrollIntoView({behavior: 'smooth', block: 'start'});
          el.style.top = top;
          el.style.position = pos;
      } else {
        el.scrollIntoView();
      }
    });
    window.addEventListener('scroll', (e) => {
      if (window.scrollY >= el.offsetTop + (link.dataset.href == 'technologies' ? (-180) : 0)) {
        var navLinks = [...document.querySelectorAll('.navbar a')];
        navLinks.map(el => {
            navLinks.find(link => link.classList.contains('active'))?.classList.remove('active');
            link.classList.add('active');
        })
      }
    });
  }
});


// rocket
window.onload = function() {
  window.addEventListener('scroll', () => {
    rocketMoves();
  });

  function rocketMoves() {
    var rocket = document.querySelector('.rocket');
    rocket?.addEventListener('click', (e) => window.scrollTo(0, 0));
    if (window.scrollY >= height - 200 && rocket.style.animationName == "rocketBye") {
      rocket.style.animation = "rocketWelcome 0.5s linear 0.3s 1 alternate"; 
      rocket.style.animationFillMode = "forwards";
      setTimeout(() => {
        if (window.scrollY >= height - 200) rocket.style.animation = "rocketInfinite 0.5s linear 0s infinite  alternate";
      }, 1000)
    };
    if (window.scrollY < height - 200 && (rocket.style.animationName == "rocketInfinite" || rocket.style.animationName == "rocketWelcome")) {
      rocket.style.animation = "rocketBye 0.5s linear 0s 1 alternate";
      rocket.style.animationFillMode = "forwards";
    };
    if (window.scrollY >= height - 200 && rocket.style.animationName != "rocketInfinite" && rocket.style.animationName != "rocketBye") {
      rocket.style.animation = "rocketWelcome 0.5s linear 0.3s 1 alternate"; 
      rocket.style.animationFillMode = "forwards";
      setTimeout(() => {
        if (window.scrollY >= height - 200) rocket.style.animation = "rocketInfinite 0.5s linear 0s infinite  alternate";
      }, 1000)
    };
  }
}

// tech
var isDone = false;
window.addEventListener('scroll', () => {
  if (window.scrollY >= document.getElementById('technologies').offsetTop - 200 && !isDone) {
    isDone = true;
    document.querySelectorAll('.progress-bar').forEach(progress => {
      var label = progress.querySelector('.percent-label'),
        percent = parseInt(progress.dataset.percent);
      if (progress.style.width != `${percent}%`) {
        for (var i =0; i <= percent; i++) {
          doSetTimeout(i);
        }
        function doSetTimeout(i) {
          setTimeout(() =>  {progress.style.width = `${i}%`;label.innerHTML = `${i}%`}, 15 * i);
        }
      }
     
    });
  } 
});


// toggleMode
var isDarkMode = localStorage.getItem('isDarkMode') ?? true;
document.querySelector('.toggleMode').addEventListener('click', () => {
  toggleMode();
});
function toggleMode() {
  if (JSON.parse(isDarkMode)) {
    document.querySelector('.toggleMode').innerHTML = '☀️';
    document.documentElement.style.setProperty(`--main-bg-color`, '#8b1414');
    document.documentElement.style.setProperty(`--sub-bg-color-1`, '#03071E');
    document.documentElement.style.setProperty(`--sub-font-color-2`, '#edf2f4');
    document.documentElement.style.setProperty(`--shadow-color`, '#ffb800');
    document.documentElement.style.setProperty(`--shadow-px`, '3px');
  } else {
    document.querySelector('.toggleMode').innerHTML = '<svg aria-hidden="true" focusable="false" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" width="2.4rem" height="2.4rem"><path fill="currentColor" d="M283.211 512c78.962 0 151.079-35.925 198.857-94.792 7.068-8.708-.639-21.43-11.562-19.35-124.203 23.654-238.262-71.576-238.262-196.954 0-72.222 38.662-138.635 101.498-174.394 9.686-5.512 7.25-20.197-3.756-22.23A258.156 258.156 0 0 0 283.211 0c-141.309 0-256 114.511-256 256 0 141.309 114.511 256 256 256z"></path></svg>';
    document.documentElement.style.setProperty(`--main-bg-color`, '#bb1d1d');
    document.documentElement.style.setProperty(`--sub-bg-color-1`, '#edf2f4');
    document.documentElement.style.setProperty(`--sub-font-color-2`, '#03071E');
    document.documentElement.style.setProperty(`--shadow-color`, '#03071E');
    document.documentElement.style.setProperty(`--shadow-px`, '5px');
  }
  localStorage.setItem('isDarkMode', isDarkMode);
  isDarkMode = !JSON.parse(isDarkMode);
}
toggleMode();

let portfolioCards = document.querySelectorAll('.card-container');
function checkSlide(e) {
  portfolioCards.forEach(card => {
    if (window.scrollY >= document.getElementById('portfolio').offsetTop - 150) {
      if (card.className.includes('card-left')) card.style = 'left: 0;';
      else card.style = 'right: 0;';
    };
  });
}
window.addEventListener('scroll', checkSlide);